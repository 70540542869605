/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { FindUsersResponseDto } from '../models/find-users-response-dto';
import { Operation } from '../models/operation';
import { UserInfoDto } from '../models/user-info-dto';

@Injectable({
  providedIn: 'root',
})
export class GeneratedUserService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation findUsers
   */
  static readonly FindUsersPath = '/api/users';

  /**
   * Finds users by a given filter.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findUsers()` instead.
   *
   * This method doesn't expect any request body.
   */
  findUsers$Response(params: {
    type?: string;
    s?: string;
    greylist?: string;
    inumis_id?: string;
    prebid_alert?: string;
    verification_req?: string;
    verification_state?: string;
    '$sortby': string;
    '$sortdir': string;
    '$page': number;
    '$maxpagesize': number;
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<FindUsersResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedUserService.FindUsersPath, 'get');
    if (params) {
      rb.query('type', params.type, {});
      rb.query('s', params.s, {});
      rb.query('greylist', params.greylist, {});
      rb.query('inumis_id', params.inumis_id, {});
      rb.query('prebid_alert', params.prebid_alert, {});
      rb.query('verification_req', params.verification_req, {});
      rb.query('verification_state', params.verification_state, {});
      rb.query('$sortby', params['$sortby'], {});
      rb.query('$sortdir', params['$sortdir'], {});
      rb.query('$page', params['$page'], {});
      rb.query('$maxpagesize', params['$maxpagesize'], {});
      rb.header('X-API-Version', params['X-API-Version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FindUsersResponseDto>;
      })
    );
  }

  /**
   * Finds users by a given filter.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findUsers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findUsers(params: {
    type?: string;
    s?: string;
    greylist?: string;
    inumis_id?: string;
    prebid_alert?: string;
    verification_req?: string;
    verification_state?: string;
    '$sortby': string;
    '$sortdir': string;
    '$page': number;
    '$maxpagesize': number;
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<FindUsersResponseDto> {

    return this.findUsers$Response(params,context).pipe(
      map((r: StrictHttpResponse<FindUsersResponseDto>) => r.body as FindUsersResponseDto)
    );
  }

  /**
   * Path part for operation getUser
   */
  static readonly GetUserPath = '/api/users/{id}';

  /**
   * Gets an users by its id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUser$Response(params: {
    id: number;
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<UserInfoDto>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedUserService.GetUserPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('X-API-Version', params['X-API-Version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserInfoDto>;
      })
    );
  }

  /**
   * Gets an users by its id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUser(params: {
    id: number;
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<UserInfoDto> {

    return this.getUser$Response(params,context).pipe(
      map((r: StrictHttpResponse<UserInfoDto>) => r.body as UserInfoDto)
    );
  }

  /**
   * Path part for operation patchUser
   */
  static readonly PatchUserPath = '/api/users/{id}';

  /**
   * Patches an user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchUser()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchUser$Response(params: {
    id: number;
    'X-API-Version'?: string;
    body: Array<Operation>
  },
  context?: HttpContext

): Observable<StrictHttpResponse<UserInfoDto>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedUserService.PatchUserPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('X-API-Version', params['X-API-Version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserInfoDto>;
      })
    );
  }

  /**
   * Patches an user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchUser$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchUser(params: {
    id: number;
    'X-API-Version'?: string;
    body: Array<Operation>
  },
  context?: HttpContext

): Observable<UserInfoDto> {

    return this.patchUser$Response(params,context).pipe(
      map((r: StrictHttpResponse<UserInfoDto>) => r.body as UserInfoDto)
    );
  }

}
